import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';

import './App.css';

import 'react-toastify/dist/ReactToastify.css';
import { UserContextProvider } from './context/User.context';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <UserContextProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ToastContainer />
              <ScrollToTop />
              <StyledChart />
              <Router />
            </LocalizationProvider>
          </UserContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
