import { Helmet } from 'react-helmet-async';
import {
  Button,
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { ApiUrlBackend } from '../config';

export default function BalancePage() {
  const [loader, setLoader] = useState(true);
  const [accountsData, setAccountsData] = useState([]);

  const getDataAccount = async () => {
    const allData = await axios.get(`${ApiUrlBackend}/api/balance`);

    setAccountsData(allData.data);
    setLoader(false);
  };

  useEffect(() => {
    getDataAccount();
  }, []);

  return (
    <>
      <Helmet>
        <title> Get balance of the accounts</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Top Up Battle Prize
        </Typography>
        {loader && (
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
            <CircularProgress size={24} />
          </Container>
        )}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>walletAddress</TableCell>
                <TableCell>matic Balance</TableCell>
                <TableCell>usdt Balance</TableCell>
                <TableCell>Topup Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accountsData.map((item) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>{item.walletAddress}</TableCell>
                      <TableCell>{item.maticBalance}</TableCell>
                      <TableCell>{item.usdtBalance}</TableCell>
                      <TableCell>
                        <Button variant="contained">Add Balance</Button>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
