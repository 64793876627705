import { createContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import LottiePlayer from 'lottie-react';
import LoaderJson from './loader.json';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);

  const [loginPin, setLoginPin] = useState('');

  const handleLoader = (state) => {
    setLoader(state);
  };

  useEffect(() => {
    const pin = localStorage.getItem('loginPin');

    const parsePin = JSON.parse(pin);
    setLoginPin(parsePin);
  }, []);

  return (
    <>
      {loader && (
        <>
          <div
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1111, background: '#00000078' }}
          >
            <LottiePlayer
              animationData={LoaderJson}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        </>
      )}
      <UserContext.Provider value={{ handleLoader, setLoginPin, loginPin }}>{children}</UserContext.Provider>
    </>
  );
};
