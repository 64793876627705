import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { toast } from 'react-toastify';
import Iconify from '../../../components/iconify';
import { UserContext } from '../../../context/User.context';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { account, setLoginPin, loginPin } = useContext(UserContext);

  const handleClick = async () => {
    if (email && password) {
      try {
        const query = `${email}_${password}`;
        setLoginPin(query);

        localStorage.setItem('loginPin', JSON.stringify(query));
        toast.success('Login successful');
        // navigate('/dashboard', { replace: true });
      } catch (err) {
        toast.error('got error');
      }
    } else {
      toast.error('please enter your email and password');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>

      {loginPin && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
          </Stack>

          <Link to={'/dashboard/app'}>
            <LoadingButton fullWidth size="large" type="submit" variant="contained">
              Go to dashboard
            </LoadingButton>
          </Link>
        </>
      )}
    </>
  );
}
