import { Navigate, useRoutes } from 'react-router-dom';
import { useContext } from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import { UserContext } from './context/User.context';
//
import BattlePage from './pages/BattlePage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import BillionsNFTsPage from './pages/BillionsNFTPage';
import DashboardAppPage from './pages/DashboardAppPage';
import NotificationPage from './pages/NotificationPage';
import BalancePage from './pages/BalancePage';

// ----------------------------------------------------------------------

const PrivateRoute = ({ element }) => {
  // const { currentUser } = useAuth();
  const { loginPin } = useContext(UserContext);

  return loginPin === process.env.REACT_APP_LOGINPIN ? element : <Navigate to="/login" />;
};

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoute element={<DashboardLayout />} />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'balance', element: <BalancePage /> },
        // { path: 'distribute', element: <DistributePage /> },
        // { path: 'nfts', element: <BillionsNFTsPage /> },
        // { path: 'battle', element: <BattlePage /> },
        // { path: 'notification', element: <NotificationPage /> },
        // { path: 'battleEnd', element: <BattleEndPage /> },
        // { path: 'nftpack/add-price', element: <NFTPackPrice /> },
        // { path: 'boosterwheel/add-price', element: <BoosterWheelPrice /> },
        // { path: 'secondary-sale-commission', element: <SecondarySaleAdminCommission /> },
        // { path: 'usdt-allowance', element: <UsdtAllowance /> },
        // { path: 'top-up-battle-prize', element: <BattlePrize /> },
        // { path: 'dao-nft-details', element: <DaoNftPrice /> },
        // { path: 'set-battle-commission', element: <BattlePrizeCommission /> },
        // { path: 'nft-buy-pack-transactions', element: <NFTBuyPackTransactions /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
