// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Balance Page',
    path: '/dashboard/balance',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'Distribute Nfts',
  //   path: '/dashboard/distribute',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'billionsNFT',
  //   path: '/dashboard/nfts',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'notifications',
  //   path: '/dashboard/notification',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'New battle',
  //   path: '/dashboard/battle',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Battle Results',
  //   path: '/dashboard/battleEnd',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
  // {
  //   title: 'NFT Pack Price',
  //   path: '/dashboard/nftpack/add-price',
  //   icon: icon('ic_user'),
  // },
  // {
  //   title: 'Booster Wheel Price',
  //   path: '/dashboard/boosterwheel/add-price',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Secondary Sale Commission',
  //   path: '/dashboard/secondary-sale-commission',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'USDT Allowance',
  //   path: '/dashboard/usdt-allowance',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Top Up Battle Prize',
  //   path: '/dashboard/top-up-battle-prize',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Set DAO NFT Price',
  //   path: '/dashboard/dao-nft-details',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'Battle price Commission',
  //   path: '/dashboard/set-battle-commission',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'NFT Pack Transactions',
  //   path: '/dashboard/nft-buy-pack-transactions',
  //   icon: icon('ic_blog'),
  // },
];

export default navConfig;
